/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import Amplify from "aws-amplify";

let myAppConfig = {
  aws_project_region: "ap-northeast-1",
  aws_cognito_identity_pool_id:
    "ap-northeast-1:78cf3729-3f7f-47ce-a635-494c10e589ce",
  aws_cognito_region: "ap-northeast-1",
  aws_user_pools_id: "ap-northeast-1_dfCEnf9Ss",
  aws_user_pools_web_client_id: "33tfi5bjvlukskph843q5949i4",
  aws_appsync_graphqlEndpoint:
    "https://erygaso7gja5hiny5stgoo2whm.appsync-api.ap-northeast-1.amazonaws.com/graphql",
  aws_appsync_region: "ap-northeast-1",
  aws_appsync_authenticationType: "AWS_IAM",
  aws_user_files_s3_bucket:
    "gunsye4b0a5c5db854bc09d1ab70531e7f7fbstorages36902733b-prod"
};

if (process.env.GATSBY_ACTIVE_ENV !== "prod") {
  myAppConfig = {
    aws_project_region: "ap-northeast-1",
    aws_cognito_identity_pool_id:
      "ap-northeast-1:f1490f9d-08a5-4373-bfc2-2c5f98d1747c",
    aws_cognito_region: "ap-northeast-1",
    aws_user_pools_id: "ap-northeast-1_JHMojw9LX",
    aws_user_pools_web_client_id: "6pbsn8r0al2l47d621ddf2gl8g",
    aws_appsync_graphqlEndpoint:
      "https://b7vjhtzxhjaxnfm6k4vo6jofo4.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    aws_appsync_region: "ap-northeast-1",
    aws_appsync_authenticationType: "AWS_IAM",
    aws_user_files_s3_bucket: "gunsye4b0a5c5db854bc09d1ab70531e7f7fb62540-test"
  };
}

Amplify.configure(myAppConfig);
